import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import { Trash2 } from 'react-feather';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { useAppStore } from 'hooks/useAppStore';
import './ImportAndUpload.scss';
import fileUpload from 'assets/images/ai-assistant/file-upload.svg';
import { AnalyzeContentInputForm } from 'types';
import { useMutation } from 'react-query';
import { uploadFiles } from 'apis/AiChat/AiChat';
import { removeUUIDFromFilename } from 'utils';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { useAnalyzeContent } from 'hooks/ai-chat';
import AIMascot from '../AIMascot/AIMascot';
import AILoading from '../AILoading/AILoading';
import { Loading } from 'components/Loading';

const ImportAndUpload = ({ chatId, analyzeSuccess }: { chatId: string; analyzeSuccess: () => void }) => {
  const {
    userInfo: { fullName },
  } = useAppStore();
  const { mutate: analyzeContent, isLoading: isAnalyzing, isSuccess } = useAnalyzeContent();

  const { register, handleSubmit, watch, setValue } = useForm<AnalyzeContentInputForm>({
    defaultValues: {
      fileUrls: [],
      webUrls: [],
      text: '',
    },
  });
  const inputUrlRef = useRef<HTMLInputElement>(null);

  const [fileError, setFileError] = useState<string | null>(null);
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);

  const { mutate: uploadMutate } = useMutation((files: File[]) => uploadFiles(chatId, files), {
    onMutate: files => {
      setPendingFiles(prev => [...prev, ...files]);
    },
    onSuccess: data => {
      setValue('fileUrls', [...watch('fileUrls'), ...data]);
      setPendingFiles([]);
    },
    onError: () => {
      setPendingFiles([]);
    },
  });

  const supportedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
  ];

  const watchFiles = watch('fileUrls');
  const watchUrls = watch('webUrls');
  const MAX_FILE_UPLOAD = 5;
  const MAX_FILE_SIZE_MB = 10;
  const FILE_SIZE_10MB = MAX_FILE_SIZE_MB * 1024 * 1024; // 10MB

  const onSubmit = async (data: AnalyzeContentInputForm) => {
    if (data.fileUrls?.length > MAX_FILE_UPLOAD) {
      setFileError(`You can only upload up to ${MAX_FILE_UPLOAD} files`);
      return;
    }

    setFileError(null);

    analyzeContent({
      chatId,
      payload: {
        fileUrls: data.fileUrls,
        webUrls: data.webUrls,
        text: data.text,
      },
    });
  };

  const validateAndUploadFiles = (files: File[]) => {
    const validFiles = files.filter(file => {
      const isValidType = supportedFileTypes.some(type => file.type.startsWith(type));
      const isValidSize = file.size <= FILE_SIZE_10MB;
      return isValidType && isValidSize;
    });

    if (validFiles.length !== files.length) {
      setFileError(`Some files were skipped due to unsupported type or size exceeding ${MAX_FILE_SIZE_MB}MB`);
    } else {
      setFileError(null);
    }

    if (validFiles.length > 0) {
      uploadMutate(validFiles);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      validateAndUploadFiles(files);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);
      validateAndUploadFiles(files);
    }
  };

  const removeFile = (index: number) => {
    const updatedFiles = watchFiles.filter((_, i) => i !== index);
    setValue('fileUrls', updatedFiles);
    setFileError(null);
  };

  const addUrl = () => {
    const url = inputUrlRef.current?.value;
    if (url) {
      setValue('webUrls', [...watchUrls, url]);
      inputUrlRef.current.value = '';
    }
  };

  const removeUrl = (index: number) => {
    const updatedUrls = watchUrls.filter((_, i) => i !== index);
    setValue('webUrls', updatedUrls);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 5000);
    setValue('text', value);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const currentContent = watch('text');
    const newContent = (currentContent + pastedText).slice(0, 5000);
    setValue('text', newContent);
  };

  useEffect(() => {
    if (isSuccess) {
      analyzeSuccess();
    }
  }, [isSuccess]);

  if (isAnalyzing)
    return (
      <div className="p-4">
        <AILoading />
      </div>
    );

  return (
    <div className="brainstorming-content d-flex flex-column gap-2 p-4">
      <>
        <div className="assistant">
          <div className="d-flex align-items-center py-1">
            <AIMascot
              className="ai-mascot ai-mascot-small"
              faceSrc={happyFace}
              isFloat={false}
            />
            <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
          </div>
          <div className="guide p-2 font-size-14 fw-normal text-neutral-900">
            Please begin by giving me some context! You can import files, share URL links, or paste the text of your
            content.
          </div>
        </div>
        <div className="human-chat">
          <div className="import-upload-wrapper">
            <div className="d-flex justify-content-end font-size-12 fw-semibold text-neutral-600 mb-1">{fullName}</div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="import-upload-section d-flex flex-column p-2 bg-primary-50 gap-3"
            >
              <div className="d-flex flex-column gap-2">
                <div>
                  <div className="mb-1 font-size-14 fw-medium text-neutral-900">Import file</div>
                  <div className="fonts-size-14 fw-normal text-neutral-500">Upload text document</div>
                </div>
                <div
                  className="drag-drop p-3 text-center cursor-pointer"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept={supportedFileTypes.join(', ')}
                    style={{ display: 'none' }}
                    id="fileInput"
                    multiple
                  />
                  <label
                    htmlFor="fileInput"
                    className="d-flex flex-column gap-3"
                  >
                    <ReactSVG src={fileUpload} />
                    <div className="d-flex flex-column gap-1">
                      <div className="font-size-14 fw-medium text-neutral-900">
                        <span className="text-primary-500">Upload a file</span> or drag and drop
                      </div>
                      <div className="font-size-12 fw-semibold text-neutral-400">
                        Documents (PDF, Word, PowerPoint, or Text files)
                      </div>
                      <div className="accept-file">PDF, DOC, DOCX, PPT, PPTX, and TXT</div>
                    </div>
                  </label>
                </div>
                {fileError && <div className="font-size-14 text-error-500">{fileError}</div>}
                {pendingFiles.map((file, index) => (
                  <div
                    className="list-file"
                    key={`pending-${index}`}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <Loading size="sm" />
                    </div>
                  </div>
                ))}
                {watchFiles.map((file, index) => (
                  <div
                    className="list-file"
                    key={`uploaded-${index}`}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="file-name font-size-12 fw-semibold text-neutral-900">
                        {removeUUIDFromFilename(file.name)}
                      </div>
                      <Trash2
                        size={16}
                        className="trash-icon cursor-pointer flex-shrink-0"
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex flex-column gap-2">
                <div>
                  <div className="mb-1 font-size-14 fw-medium text-neutral-900">Import from URL</div>
                  <div className="fonts-size-14 fw-normal text-neutral-500">
                    Paste the URL of your desired webpage here
                  </div>
                </div>

                <div className="d-flex flex-row gap-2">
                  <div className="d-flex flex-fill align-items-center search-wrapper">
                    <input
                      ref={inputUrlRef}
                      className="search-input d-flex align-items-center h-100 flex-fill w-1px text-truncate"
                      type="text"
                      placeholder="Add file URL"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={addUrl}
                  >
                    Add
                  </button>
                </div>
                {watchUrls.length > 0 &&
                  watchUrls.map((url, index) => (
                    <div
                      className="list-file"
                      key={index}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="file-name font-size-12 fw-semibold text-neutral-900 text-decoration-underline">
                          {url}
                        </div>
                        <Trash2
                          size={16}
                          className="trash-icon cursor-pointer flex-shrink-0"
                          onClick={() => removeUrl(index)}
                        />
                      </div>
                    </div>
                  ))}
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="font-size-14 fw-medium text-neutral-900">Import from text</div>
                  <div className="fonts-size-12 fw-normal text-neutral-400">{watch('text').length}/5000</div>
                </div>
                <div className="d-flex flex-fill align-items-center area-wrapper">
                  <textarea
                    {...register('text')}
                    className="area-input d-flex align-items-center flex-fill"
                    placeholder="Describe your learning material. You can either type it or paste it directly."
                    rows={5}
                    onChange={handleTextareaChange}
                    onPaste={handlePaste}
                    maxLength={5000}
                  />
                </div>
              </div>
              <div className="p-2">
                <button
                  type="submit"
                  className="btn btn-primary d-flex align-items-center"
                >
                  <SparklesIcon className="me-2" />
                  Analyse Content
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </div>
  );
};

export default ImportAndUpload;
