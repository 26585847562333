import React, { useEffect, useRef, useState } from 'react';
import './ChatMessages.scss';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { Check, ChevronRight, Copy, Edit2, RefreshCcw } from 'react-feather';
import { AppBadge } from 'components/AppBadge';
import { AIMessageActionType, AIMessageContentType, AIMessage, AIMessageType } from 'types';
import UserTextPrompt from '../UserTextPrompt/UserTextPrompt';
import Markdown from 'react-markdown';
import RefineModal from '../RefineModal/RefineModal';
import SelectContentModal from '../SelectContentModal/SelectContentModal';
import { useCopyToClipboard } from 'react-use';
import { useApplyContent } from 'hooks/ai-chat';
import { useRegenerateMessage } from 'hooks/ai-chat/useRegenerateMessage';
import AIMascot from '../AIMascot/AIMascot';
import AILoading from '../AILoading/AILoading';
import { ChatInputLoading } from '../ChatInputLoading';

interface Props {
  chatId: string;
  messages: AIMessage[];
  isChatAnalyzed: boolean;
  isNewMessageLoading: boolean;
  isAnalyzing: boolean;
}

const ChatMessages = ({ chatId, messages, isChatAnalyzed, isNewMessageLoading, isAnalyzing }: Props) => {
  const [isRefineModalOpen, setIsRefineModalOpen] = useState(false);
  const [isSelectContentTypeOpen, setIsSelectContentTypeOpen] = useState(false);
  const [currentActionType, setCurrentActionType] = useState<AIMessageActionType | null>(null);
  const [currentMessageContentType, setCurrentMessageContentType] = useState<AIMessageContentType | null>(null);
  const bottomElementRef = useRef<HTMLDivElement>();
  const { mutateAsync: regenerateMessage, isLoading: isRegenerateLoading } = useRegenerateMessage();

  const [clipboard, copy] = useCopyToClipboard();

  const { mutateAsync: applyContent, isLoading } = useApplyContent();

  const handleAction = async (message: AIMessage) => {
    if (
      message.actionType === AIMessageActionType.GenerateContent ||
      message.actionType === AIMessageActionType.RefineContent
    ) {
      const data = await applyContent({ chatId, messageId: message.id });

      // redirect to the new content
      window.location.href = `/contents/new?library=${data.metadata.structuredContent.library}&aiMessageId=${data.id}`;
    } else handleOpenSelectContentModal();
  };

  const actionLabel = (actionType: AIMessageActionType) => {
    if (actionType === AIMessageActionType.GenerateContent || actionType === AIMessageActionType.RefineContent)
      return 'Apply & Create';

    return 'Select Content';
  };

  const titleLabel = (actionType: AIMessageActionType) => {
    if (actionType === AIMessageActionType.GenerateContent || actionType === AIMessageActionType.RefineContent)
      return 'Review Content';

    return 'Review Context';
  };

  const descriptionLabel = (actionType: AIMessageActionType) => {
    switch (actionType) {
      case AIMessageActionType.GenerateContent:
      case AIMessageActionType.RefineContent:
        return 'Please review the text version of your content below and select <strong>Apply & Create</strong> to create content.';

      case AIMessageActionType.RefineContext:
        return 'Please review the text version of your content below and select a relevant content type from our list.';

      default:
        return "Please review your content's context and refine if needed.";
    }
  };

  const handleRefineClick = (actionType: AIMessageActionType, messageContentType: AIMessageContentType) => {
    setCurrentActionType(actionType);
    setCurrentMessageContentType(messageContentType);
    setIsRefineModalOpen(true);
    setIsSelectContentTypeOpen(false);
    scrollToBottom();
  };

  const handleCloseRefineModal = () => {
    setIsRefineModalOpen(false);
    setCurrentActionType(null);
    setCurrentMessageContentType(null);
  };

  const handleOpenSelectContentModal = () => {
    setIsSelectContentTypeOpen(true);
    setIsRefineModalOpen(false);
    scrollToBottom();
  };

  const handleCloseSelectContentModal = () => {
    setIsSelectContentTypeOpen(false);
  };

  const getRefineLabel = (actionType: AIMessageActionType | null) => {
    if (actionType === AIMessageActionType.GenerateContent || actionType === AIMessageActionType.RefineContent) {
      return 'Refine Content';
    }
    return 'Refine Context';
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      bottomElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setIsRefineModalOpen(false);
    setIsSelectContentTypeOpen(false);
  }, [chatId]);

  return (
    <>
      {messages.map((message, index) => (
        <div
          key={index}
          className="chat-list"
        >
          {message.messageType === AIMessageType.AI ? (
            <>
              <div className="d-flex align-items-center py-1">
                <AIMascot
                  faceSrc={happyFace}
                  className="ai-mascot ai-mascot-small"
                  isFloat={false}
                />
                <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
              </div>
              <div className="ai-response d-flex flex-column gap-3 p-2 bg-white">
                {isChatAnalyzed && (
                  <div className="font-size-14">
                    <div className="fw-medium text-neutral-900 mb-1">{titleLabel(message.actionType)}</div>
                    <div
                      className="fw-normal text-neutral-500"
                      dangerouslySetInnerHTML={{ __html: descriptionLabel(message.actionType) }}
                    />
                  </div>
                )}
                <Markdown className="custom-markdown">{message.content}</Markdown>

                <div className="d-flex align-items-center gap-2 p-2">
                  {index === messages.length - 1 &&
                    message.messageType === AIMessageType.AI &&
                    (isChatAnalyzed || message.messageContentType === AIMessageContentType.Content) && (
                      <div className="d-flex align-items-center gap-3">
                        {isChatAnalyzed && (
                          <>
                            <button
                              className="btn btn-primary d-flex align-items-center"
                              onClick={() => handleAction(message)}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <SparklesIcon className="me-2" />
                              )}
                              {actionLabel(message.actionType)}
                            </button>
                            <button
                              className="btn btn-outline-primary d-flex align-items-center"
                              onClick={() => handleRefineClick(message.actionType, message.messageContentType)}
                            >
                              <Edit2 className="me-2" /> Refine
                            </button>
                          </>
                        )}
                        {message.messageContentType === AIMessageContentType.Content && (
                          <div className="d-flex align-items-center gap-1 cursor-pointer text-neutral-400">
                            <div
                              className="action-icon d-flex align-items-center"
                              onClick={() => regenerateMessage({ chatId, messageId: message.id })}
                            >
                              <RefreshCcw size={16} />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  {clipboard.value === message.content ? (
                    <div className="p-2">
                      <Check
                        className="text-green-500"
                        size={20}
                      />
                    </div>
                  ) : (
                    <div
                      className="action-icon d-flex align-items-center cursor-pointer"
                      onClick={() => copy(message.content)}
                    >
                      <Copy
                        size={16}
                        className="text-neutral-400"
                      />
                    </div>
                  )}
                </div>
              </div>
              {(message.actionType === AIMessageActionType.GenerateContent ||
                message.actionType === AIMessageActionType.RefineContent) &&
                index === messages.length - 1 &&
                message.messageType === AIMessageType.AI && (
                  <div className="d-flex flex-column gap-2 mt-1">
                    <div className="d-flex align-items-center justify-content-end fonts-size-14 fw-medium text-neutral-500">
                      Using current context
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-end font-size-14 cursor-pointer custom-badge"
                      onClick={handleOpenSelectContentModal}
                    >
                      <AppBadge
                        type="neutral-outline-hover"
                        label={
                          <>
                            Want to create a different content type with current context? Click here
                            <ChevronRight
                              size={16}
                              className="text-neutral-900 ms-2"
                            />
                          </>
                        }
                      />
                    </div>
                  </div>
                )}
            </>
          ) : (
            <div className="human-chat">
              <UserTextPrompt message={message} />
            </div>
          )}
        </div>
      ))}

      {isNewMessageLoading && <ChatInputLoading />}
      {isRefineModalOpen && (
        <div className="d-flex justify-content-end">
          <RefineModal
            label={getRefineLabel(currentActionType)}
            onClose={handleCloseRefineModal}
            currentMessageContentType={currentMessageContentType}
            chatId={chatId}
          />
        </div>
      )}
      {isSelectContentTypeOpen && (
        <div className="d-flex justify-content-end">
          <SelectContentModal
            onClose={handleCloseSelectContentModal}
            chatId={chatId}
          />
        </div>
      )}

      {(isRegenerateLoading || isAnalyzing) && <AILoading />}
      <div ref={bottomElementRef}></div>
    </>
  );
};

export default ChatMessages;
