export enum ContentType {
  DRAG_THE_WORDS = 'Drag the Words',
  TRUE_FALSE_QUESTION = 'True/False Question',
  FILL_IN_THE_BLANKS = 'Fill in the Blanks',
  MARK_THE_WORDS = 'Mark the Words',
  MULTIPLE_CHOICE = 'Multiple Choice',
  CROSSWORD = 'Crossword',
  QUESTION_SET = 'Question Set',
  DIALOG_CARDS = 'Dialog Cards',
}
