import React from 'react';
import './SidebarHistory.scss';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';
import thinkingFace from 'assets/images/ai-assistant/face-thinking.svg';
import workingFace from 'assets/images/ai-assistant/face-working.svg';
import { ArrowRight, Edit2, MoreVertical, Trash2 } from 'react-feather';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useDeleteChat, useFetchChatHistory } from 'hooks/ai-chat';
import { AiChatHistory, AiChatType } from 'types';
import useModal from 'hooks/useModal';
import { RenameChatModalProps } from 'components/ModalManager/RenameChat';
import AIMascot from '../AIMascot/AIMascot';

interface Props {
  isWelcome: boolean;
  onChatSelect: (chatId: string) => void;
  selectedChatId: string;
  changeViewMode: (mode: AiChatType) => void;
}

const SidebarHistory = observer((props: Props) => {
  const { isWelcome, onChatSelect, changeViewMode, selectedChatId } = props;
  const { data: chatHistory } = useFetchChatHistory();
  const { openModal, closeModal } = useModal();
  const { mutateAsync: deleteChat } = useDeleteChat();

  const openRenameChatModal = (chat: AiChatHistory) => {
    openModal('rename-chat', {
      chat,
      onCancel: closeModal,
    } as RenameChatModalProps);
  };

  const openDeleteChatModal = (chat: AiChatHistory) => {
    openModal('warning', {
      title: 'Are you sure you want to delete this chat?',
      content: "This chat will be deleted and won't be able to retrieve.",
      titleIcon: (
        <Trash2
          className="primary-500"
          strokeWidth={1.5}
          size={24}
        />
      ),
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      onCancel: closeModal,
      onAccept: async (done: () => void) => {
        await deleteChat(chat.id);
        done();
        closeModal();
        if (selectedChatId === chat.id) {
          onChatSelect(null);
          changeViewMode(null);
        }
      },
    });
  };

  return (
    <div className="sidebar-history d-flex flex-column justify-content-between h-100">
      <div
        className="h-100 d-flex flex-column p-3"
        style={{ overflowY: 'auto' }}
      >
        <div className="mb-2 font-size-14 fw-medium text-neutral-500">Latest</div>
        <div className="flex-fill d-flex flex-column gap-2 cursor-pointer">
          {chatHistory
            ?.filter(chat => chat.name !== null)
            .map((chat, index) => (
              <div
                key={index}
                className={`d-flex justify-content-between p-1 rounded-3 sidebar-history__question ${
                  selectedChatId === chat.id ? 'active' : ''
                }`}
                onClick={() => onChatSelect(chat.id)}
              >
                <div className="d-flex gap-1">
                  <ChatBubbleOvalLeftEllipsisIcon className="text-neutral-400 flex-shrink-0" />
                  <div className="font-size-14 fw-normal text-neutral-700 sidebar-history__title">{chat.name}</div>
                </div>
                <UncontrolledDropdown
                  direction="end"
                  className="pointer"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <DropdownToggle tag="span">
                    <MoreVertical
                      size={16}
                      className="more-vertical-icon align-top flex-shrink-0"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="end-0 p-2 history-actions-dropdown">
                    <DropdownItem>
                      <div
                        className="d-flex align-items-center dropdown-action"
                        onClick={() => openRenameChatModal(chat)}
                      >
                        <Edit2
                          size={16}
                          className="text-neutral-400 flex-shrink-0"
                        />
                        <span className="ms-2 font-size-14 fw-normal text-neutral-900">Rename</span>
                      </div>
                    </DropdownItem>
                    <DropdownItem
                      divider
                      className="my-1"
                    />
                    <DropdownItem>
                      <div
                        className="d-flex align-items-center dropdown-action"
                        onClick={() => openDeleteChatModal(chat)}
                      >
                        <Trash2
                          size={16}
                          className="text-neutral-400 flex-shrink-0"
                        />
                        <span className="ms-2 font-size-14 fw-normal text-neutral-900">Delete</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            ))}
        </div>
      </div>
      {!isWelcome && (
        <div className="p-3 cursor-pointer">
          <div
            className="sidebar-history__option rounded-3"
            onClick={() => changeViewMode(AiChatType.BRAINSTORM)}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2">
                <div className="icon d-flex align-items-center justify-content-center flex-shrink-0">
                  <AIMascot
                    faceSrc={thinkingFace}
                    className="ai-mascot ai-mascot-medium"
                    isFloat={false}
                  />
                </div>
                <div className="font-size-14 fw-medium text-gray-800">Brainstorm ideas and content</div>
              </div>
              <ArrowRight
                size={16}
                className="arrow-icon align-top flex-shrink-0"
              />
            </div>
          </div>
          <div
            className="sidebar-history__option rounded-3 mt-2"
            onClick={() => changeViewMode(AiChatType.IMPORT)}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2">
                <div className="icon d-flex align-items-center justify-content-center flex-shrink-0">
                  <AIMascot
                    faceSrc={workingFace}
                    className="ai-mascot ai-mascot-medium"
                    isFloat={false}
                  />
                </div>
                <div className="font-size-14 fw-medium text-gray-800">Import and create content</div>
              </div>
              <ArrowRight
                size={16}
                className="arrow-icon align-top flex-shrink-0"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default SidebarHistory;
