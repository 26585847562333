import React, { useEffect, useMemo, useRef } from 'react';
import './ChatMessagesHistory.scss';
import classNames from 'classnames';
import {
  addMessageToChatHistory,
  useCreateMessage,
  useFetchChatDetail,
  useFetchChatHistory,
  useUpdateChat,
} from 'hooks/ai-chat';
import {
  AIMessageActionType,
  AIMessageContentType,
  AIMessageType,
  AiChatHistory,
  AiChatType,
  ChatDetailResponse,
} from 'types';
import { ChatInput } from '../ChatInput';
import UserUploadPrompt from '../UserUploadPrompt/UserUploadPrompt';
import { AppBadge } from 'components/AppBadge';
import { ChevronRight } from 'react-feather';
import { useQueryClient } from 'react-query';
import ChatMessages from '../ChatMessages/ChatMessages';
import AIMascot from '../AIMascot/AIMascot';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { getBrainstormTopicSuggestions } from 'components/AIOffset';

export const ChatMessagesHistory = ({ chatId }: { chatId: string; selectChat: (id: string) => void }) => {
  const { data: chatDetail } = useFetchChatDetail(chatId);
  const messages = chatDetail?.messages || [];
  const importedDocuments = chatDetail?.aiChat?.importedDocuments || {};
  const queryClient = useQueryClient();
  const { data: chatHistory = [] } = useFetchChatHistory();
  const { mutateAsync: createNewMessage, isLoading: isCreateMessageLoading } = useCreateMessage();
  const { mutateAsync: analyzeContent, isLoading: isAnalyzing } = useCreateMessage();
  const { mutate: updateChat } = useUpdateChat({});
  const bottomElementRef = useRef<HTMLDivElement>();

  const brainstormChats = useMemo<AiChatHistory[]>(() => {
    return chatHistory.filter(chat => chat.chatType === AiChatType.BRAINSTORM);
  }, [chatHistory]);

  const defaultChats = useMemo<Partial<AiChatHistory>[]>(() => {
    return brainstormChats.length ? brainstormChats : getBrainstormTopicSuggestions();
  }, [chatHistory]);

  const onAnalyzeBrainstormingContent = async () => {
    const lastMessage = messages[messages.length - 1].content;

    addMessageToChatHistory({
      queryClient,
      chatId,
      message: {
        messageType: AIMessageType.Human,
        content: 'Finish Brainstorm & Analyse Content',
        messageContentType: AIMessageContentType.Text,
        actionType: AIMessageActionType.Chat,
      },
    });

    const dataMessage = await analyzeContent({
      chatId,
      content: lastMessage,
      actionType: AIMessageActionType.GenerateContext,
    });

    queryClient.setQueryData<ChatDetailResponse>(['chat-detail', chatId], chatDetail => {
      return {
        ...chatDetail,
        aiChat: {
          ...chatDetail.aiChat,
          aiContext: dataMessage.content,
        },
      };
    });
  };

  const onSubmitInput = async ({
    chatId,
    content,
    actionType,
  }: {
    chatId: string;
    content: string;
    actionType: AIMessageActionType;
  }) => {
    const newInputMessage = await createNewMessage({
      chatId,
      content,
      actionType,
    });

    // Add new chat to sidebar history when user first input in brainstorm
    const hasMessagesBefore = messages.length > 0;
    if (!hasMessagesBefore) {
      updateChatName(newInputMessage.content.split('.')[0]);
    }
  };

  const updateChatName = (name: string) => {
    updateChat({ chatId, name });

    queryClient.setQueryData<AiChatHistory[]>('chat-history', (chatsList: AiChatHistory[] = []) => {
      return [{ ...chatDetail.aiChat, name }, ...chatsList];
    });
  };

  const isChatAnalyzed = useMemo(() => {
    return !!chatDetail?.aiChat?.aiContext;
  }, [chatDetail]);

  const welcomeMessage = useMemo(() => {
    return chatDetail?.aiChat?.chatType === AiChatType.IMPORT ? (
      <div className="d-flex justify-content-end">
        <UserUploadPrompt
          chatId={chatId}
          importedDocuments={importedDocuments}
        />
      </div>
    ) : (
      !messages.length && (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center">
            <AIMascot
              className="ai-mascot ai-mascot-small"
              faceSrc={happyFace}
              isFloat={false}
            />
            <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
          </div>
          <div className="p-2 bg-white rounded-3 border border-primary-50 text-neutral-900">
            To get started, could you provide some context? Let me know the topics we&apos;ll be brainstorming.
          </div>
        </div>
      )
    );
  }, [chatDetail?.aiChat?.chatType, messages?.length]);

  useEffect(() => {
    setTimeout(() => {
      bottomElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 100);
  }, [messages, isCreateMessageLoading]);

  const onSelectDefaultMessage = async (content: string) => {
    addMessageToChatHistory({
      queryClient,
      chatId,
      message: {
        content,
        actionType: AIMessageActionType.Chat,
        messageContentType: AIMessageContentType.Text,
        messageType: AIMessageType.Human,
      },
    });

    await onSubmitInput({ chatId, content, actionType: AIMessageActionType.Chat });
  };

  return (
    <div
      className={classNames('chat-messages-history bg-white h-100 d-flex flex-column p-4 overflow-auto', {
        'pb-0': chatDetail?.aiChat?.chatType === AiChatType.BRAINSTORM,
      })}
    >
      {welcomeMessage}

      <div className="bg-white flex-fill d-flex flex-column gap-3 mb-4">
        <ChatMessages
          isChatAnalyzed={!!messages.length && isChatAnalyzed}
          chatId={chatId}
          messages={messages}
          isNewMessageLoading={isCreateMessageLoading}
          isAnalyzing={isAnalyzing}
        />
      </div>

      {chatDetail?.aiChat?.chatType === AiChatType.BRAINSTORM && (
        <>
          {!messages.length && (
            <div className="d-flex flex-column gap-2 mb-4">
              {brainstormChats.length > 0 && (
                <div className="d-flex align-items-center justify-content-end fonts-size-14 fw-medium text-neutral-500">
                  Your previous brainstorming topics...
                </div>
              )}

              {defaultChats.slice(0, 2).map((chat, index) => {
                return (
                  <div
                    onClick={() => onSelectDefaultMessage(chat.name)}
                    key={index}
                    className="d-flex align-items-center justify-content-end font-size-14 cursor-pointer custom-badge"
                  >
                    <AppBadge
                      type="neutral-outline-hover"
                      label={
                        <div className="w-100 d-flex align-items-center">
                          <div className="previous-brainstorm-topic w-100">{chat.name}</div>

                          <ChevronRight
                            size={16}
                            className="text-neutral-900"
                          />
                        </div>
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}

          {messages.length > 1 && !isChatAnalyzed && !isAnalyzing && !isCreateMessageLoading && (
            <div className="d-flex flex-column gap-2 mb-4">
              <div className="d-flex align-items-center justify-content-end fonts-size-14 fw-medium text-neutral-500">
                Happy with your result? Start analyse and create your content right away!
              </div>

              <div
                onClick={() => onAnalyzeBrainstormingContent()}
                className="d-flex align-items-center justify-content-end font-size-14 cursor-pointer custom-badge"
              >
                <AppBadge
                  type="neutral-outline-hover"
                  label={
                    <>
                      Finish Brainstorm & Analyse Content
                      <ChevronRight
                        size={16}
                        className="text-neutral-900 ms-2"
                      />
                    </>
                  }
                />
              </div>
            </div>
          )}

          {!isChatAnalyzed && (
            <ChatInput
              chat={chatDetail}
              submitInput={onSubmitInput}
              isLoading={isCreateMessageLoading}
            />
          )}

          <div ref={bottomElementRef}></div>
        </>
      )}
    </div>
  );
};
