import React, { useMemo, useState } from 'react';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import './AIOffset.scss';
import Header from './components/Header/Header';
import SidebarHistory from './components/SidebarHistory/SidebarHistory';
import WelcomeSection from './components/WelcomeSection/WelcomeSection';
import { AiChatType } from 'types';
import { ChatMessagesHistory } from './components/ChatMessagesHistory';
import ImportAndUpload from './components/ImportAndUpload/ImportAndUpload';
import { useCreateNewChat } from 'hooks/ai-chat/useCreateNewChat';
import { Loading } from 'components/Loading';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

interface OffcanvasMenuProps {
  className?: string;
}

const AIOffset: React.FC<OffcanvasMenuProps> = observer(({ className }) => {
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<AiChatType>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { mutateAsync: createChat, isLoading: isCreateChatLoading } = useCreateNewChat();
  const { uiStore } = useAppStore();

  const { isNavbarCollapsed, isAIOffsetOpen } = uiStore;

  const handleChatSelect = (chatId: string) => {
    setSelectedChatId(chatId);
    setViewMode(AiChatType.BRAINSTORM);
  };

  const createNewChat = async (mode: AiChatType) => {
    const newChat = await createChat({ chatType: mode });
    setSelectedChatId(newChat.id);
    setViewMode(mode);
  };

  const mainLayout = useMemo(() => {
    return !viewMode ? (
      <WelcomeSection changeViewMode={mode => createNewChat(mode)} />
    ) : viewMode === AiChatType.IMPORT ? (
      <ImportAndUpload
        chatId={selectedChatId}
        analyzeSuccess={() => {
          setViewMode(AiChatType.BRAINSTORM);
        }}
      />
    ) : (
      <ChatMessagesHistory
        selectChat={chatId => setSelectedChatId(chatId)}
        chatId={selectedChatId}
      />
    );
  }, [viewMode, selectedChatId, isCreateChatLoading]);

  return (
    <Offcanvas
      isOpen={isAIOffsetOpen}
      toggle={() => uiStore?.toggleAIOffset()}
      direction="end"
      backdrop={false}
      className={classNames(className, { expanded: expanded, 'navbar-collapsed': isNavbarCollapsed })}
    >
      <OffcanvasHeader>
        <Header
          isWelcome={!viewMode}
          onClose={() => uiStore?.toggleAIOffset()}
          expanded={expanded}
          toggleExpand={setExpanded}
          displayWelcomeScreen={() => {
            setViewMode(null);
            setSelectedChatId(null);
          }}
        />
      </OffcanvasHeader>
      <OffcanvasBody>
        <div className="d-flex flex-column flex-column-reverse justify-content-end flex-lg-row h-100">
          <div className={classNames('col-12 col-lg-3 h-100', selectedChatId ? 'd-none' : 'd-block', 'd-lg-block')}>
            <SidebarHistory
              isWelcome={!viewMode}
              onChatSelect={handleChatSelect}
              changeViewMode={mode => (mode ? createNewChat(mode) : setViewMode(null))}
              selectedChatId={selectedChatId}
            />
          </div>

          <div className={classNames('col-12 col-lg-9', { 'h-100': selectedChatId })}>
            <div className="h-100 overflow-auto bg-white">{isCreateChatLoading ? <Loading /> : mainLayout}</div>
          </div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
});

export default AIOffset;
